.rrm-home__featured-items {
  background-color: $black;
  padding: 4.5rem;
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  @media (min-width: $breakpoint-tablet) {
    padding: 4.5rem 10rem;
  }

  .rrm-home-text-area__top {
    display: inline;
    text-align: center;
    text-transform: uppercase;
    padding: 5rem 1.25rem 0;
    //width: 100%;
    max-width: 49.063rem;

    h1 {
      color: white;
      font-family: $rrm-black;
      line-height: 1.5rem;
      font-size: 3rem;
      letter-spacing: .063rem;
      margin-bottom: 0;
    }

    h1::after {
      content: "";
      /* This is necessary for the pseudo element to work. */
      display: block;
      /* This will put the pseudo element on its own line. */
      margin: 0 auto;
      /* This will center the border. */
      width: 20.063rem;
      /* Change this to whatever width you want. */
      padding-top: 1.75rem;
      /* This creates some space between the element and the border. */
      border-bottom: .0125rem solid white;
      /* This creates the border. Replace black with whatever color you want. */
    }

    h2 {
      font-family: $rrm-mdcd;
      font-weight: 100;
      letter-spacing: 0.0625rem;
      width: 100%;
      font-size: 2.125rem;
      margin-top: 1rem;
      margin-bottom: 0;
      word-wrap: none;
      word-break: keep-all;
    }

  }

  .rrm-home-featured-items__panel {
    width: 100%;
    text-align: center;
    margin: 0 auto;

    @media (min-width: $breakpoint-tablet) {
      flex-wrap: wrap;
      max-width: 12.6rem;
    }

    @media (min-width: $breakpoint-tablet-large) {
      max-width: 13.5rem;
    }

    .rrm-home-featured-items-panel__image {
      height: 11.56rem;
      width: 11.56rem;
      margin: auto;
    }

    .rrm-home-featured-items-panel__title {
      text-transform: uppercase;
      font-size: 1.25rem;
      letter-spacing: .069rem;
      font-family: $rrm-black;
      color: white;
      margin-right: 0;

      h3 {
        font-family: $rrm-black;
        font-size: 1.25rem;
        width: 10.5rem;
        //text-align: center;
        margin-top: 0.2rem;
      }
    }

    .rrm-home-featured-items-panel__text {
      display: inline-block;

      p {
        color: white;
        font-family: $font;
        margin-top: 0;
        width: 12.56rem;
        font-weight: 100;
        font-size: 16px;
      }
    }
  }

  .rrm-home-featured-item-panels {
    display: inline-block;
    padding: 4.5rem $page-margin;
    color: white;
    text-align: center;
    width: 100%;
    max-width: 97.81rem;


    .rrm-home-text-area__bottom {
      display: inline;

      p {
        color: white;
        font-family: $font;
        margin-top: 0;
        font-weight: 100;
        font-size: 18px;
      }

      #visitaddress {
        font-family: $rrm-ltstcd;
        line-height: 1.625rem;
        font-size: 1.125rem;
        color:#990000;
        font-weight: bolder;
      }
    }

    .rrm-home-text-area__button {
      padding-top: 1.5rem;
      margin-bottom: 1rem;

      button {
        font-size: 1.75rem;
        padding: 1.1rem;
        text-transform: uppercase;
      }
    }
  }

  #featured-items {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
}