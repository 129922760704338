.rrm-home__image
{
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 25rem;
    width: 100%;

    @media(min-width: $breakpoint-tablet)
    {
        width: 50%;
    }
}