@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=f8da4e52-73c3-4612-b24e-ff93be67163e");

@font-face{
    font-family:"Helvetica Neue LT";
    src:url("/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
    src:url("/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"),url("/fonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
    font-style: normal;
    font-weight: 400;
}
@font-face{
    font-family:"Helvetica Neue LT";
    src:url("/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
    src:url("/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"),url("/fonts/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"),url("/fonts/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"),url("/fonts/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"),url("/fonts/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
    font-style: normal;
    font-weight: 700;
}



@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/bd4a6c95-033c-41f0-9510-2de2be19ebc6.eot?#iefix");
    src:url("/fonts/bd4a6c95-033c-41f0-9510-2de2be19ebc6.eot?#iefix") format("eot"),url("/fonts/6b354187-add6-421c-9022-918efeb1a9d4.woff2") format("woff2"),url("/fonts/fcf607e0-9726-46de-99a0-3d80440fcad0.woff") format("woff"),url("/fonts/3a077c2d-60d8-4c1e-947f-64cd5c57b382.ttf") format("truetype"),url("/fonts/da20fd94-cdae-454e-a5d9-e1790eeaf24b.svg#da20fd94-cdae-454e-a5d9-e1790eeaf24b") format("svg");
    font-style: normal;
    font-weight: 400;
}
@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/ba84e803-3903-4d83-bec4-16b23ae8ad1d.eot?#iefix");
    src:url("/fonts/ba84e803-3903-4d83-bec4-16b23ae8ad1d.eot?#iefix") format("eot"),url("/fonts/59ba0cae-532c-4764-970e-c8e9c4dc3621.woff2") format("woff2"),url("/fonts/c17e88a4-2bac-4c58-8aea-2345de107ef7.woff") format("woff"),url("/fonts/67daed65-8aec-4c77-9664-ca53f35c3734.ttf") format("truetype"),url("/fonts/c46a8071-15d2-4934-89bc-7e0db806414d.svg#c46a8071-15d2-4934-89bc-7e0db806414d") format("svg");
    font-style: italic;
    font-weight: 400;
}
@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/65803b4f-d9d0-4ddb-a9a5-9ab6ee15827e.eot?#iefix");
    src:url("/fonts/65803b4f-d9d0-4ddb-a9a5-9ab6ee15827e.eot?#iefix") format("eot"),url("/fonts/a26b8d77-7077-41a3-aa4a-9720f909f728.woff2") format("woff2"),url("/fonts/10deb463-297b-4976-8a13-f9a98d7ea6de.woff") format("woff"),url("/fonts/143c464c-244f-4c8e-a5f2-c28f78f092fc.ttf") format("truetype"),url("/fonts/2214883f-2c66-4e68-9fc8-3641bd9200ca.svg#2214883f-2c66-4e68-9fc8-3641bd9200ca") format("svg");
    font-style: normal;
    font-weight: 700;
}
@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/83fadcc0-5f33-4dfc-af40-cac829e5be93.eot?#iefix");
    src:url("/fonts/83fadcc0-5f33-4dfc-af40-cac829e5be93.eot?#iefix") format("eot"),url("/fonts/ddf06504-e618-494d-b1b9-56e26c0bf664.woff2") format("woff2"),url("/fonts/291df853-a088-4f20-9bb4-ce8097a4011b.woff") format("woff"),url("/fonts/3f4f26ae-3bd3-4b3c-91e0-daf64c948eda.ttf") format("truetype"),url("/fonts/279fffe9-9054-428f-be89-3c9cb4f57c2d.svg#279fffe9-9054-428f-be89-3c9cb4f57c2d") format("svg");
    font-style: italic;
    font-weight: 700;
}
@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/ffcecea9-4527-4be9-95f4-bea2860d6535.eot?#iefix");
    src:url("/fonts/ffcecea9-4527-4be9-95f4-bea2860d6535.eot?#iefix") format("eot"),url("/fonts/7c432744-fd05-46c3-8d26-d656c777bd5f.woff2") format("woff2"),url("/fonts/fabc29b0-3776-4b3d-922c-cdbe3ad31146.woff") format("woff"),url("/fonts/bf5cad83-7e03-48fa-bd2c-62b8871a6873.ttf") format("truetype"),url("/fonts/f43eb9f0-5f2e-4dce-b39e-1658aafc4873.svg#f43eb9f0-5f2e-4dce-b39e-1658aafc4873") format("svg");
    font-style: normal;
    font-weight: 800;
}
@font-face{
    font-family:"Charter ITC";
    src:url("/fonts/9e7fd5fd-ca11-4190-9d19-0be802a37c9d.eot?#iefix");
    src:url("/fonts/9e7fd5fd-ca11-4190-9d19-0be802a37c9d.eot?#iefix") format("eot"),url("/fonts/8f48b96f-3535-45f2-a3e7-c029b7b0d2d4.woff2") format("woff2"),url("/fonts/c4cd5d84-191e-4241-b89f-b68e2761baa8.woff") format("woff"),url("/fonts/4062d75d-b72c-4efa-9858-3115e9ae0e6f.ttf") format("truetype"),url("/fonts/91994cd0-4680-4eeb-a64e-9be12dfb84b4.svg#91994cd0-4680-4eeb-a64e-9be12dfb84b4") format("svg");
    font-style: italic;
    font-weight: 800;
}

@font-face{
    font-family:"Helvetica Neue LT Standard Black";
    src:url("fonts/HelveticaNeueLTStd-Blk.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-Blk.woff") format("woff");
    font-style: normal;
}

@font-face{
    font-family:"Helvetica Neue LT Standard Medium Condensed";
    src:url("fonts/HelveticaNeueLTStd-MdCn.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-MdCn.woff") format("woff");
    font-style: normal;
}

@font-face{
    font-family:"Helvetica Neue LT Standard Condensed";
    src:url("fonts/HelveticaNeueLTStd-Cn.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-Cn.woff") format("woff");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: 'Helvetica Neue LT Std 57 Condensed';
    font-style: normal;
    font-weight: normal;
    src: local('Helvetica Neue LT Std 57 Condensed'), url('/fonts/HelveticaNeueLTStd-Cn.woff') format('woff');
    }
    
    
@font-face {
font-family: 'Helvetica Neue LT Std 56 Italic';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue LT Std 56 Italic'), url('/fonts/HelveticaNeueLTStd-It.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue LT Std 47 Light Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue LT Std 47 Light Condensed'), url('/fonts/HelveticaNeueLTStd-LtCn.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue LT Std 45 Light';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue LT Std 45 Light'), url('/fonts/HelveticaNeueLTStd Lt.woff') format('woff');
}


@font-face {
font-family: 'Helvetica Neue LT Std 77 Bold Condensed';
font-style: normal;
font-weight: normal;
src: local('Helvetica Neue LT Std 77 Bold Condensed'), url('/fonts/HelveticaNeueLTStd-BdCn.woff') format('woff');
}

@font-face {
    font-family: 'Helvetica Neue LT Std Condensed';
    font-style: bold;
    font-weight: bold;
    src: local('Helvetica Neue LT Std Condensed'), url('/fonts/HelveticaNeueLTStd-Cn.woff') format('woff');
    font-weight: 800;
    }
