@import "fonts";

// Breakpoints
$breakpoint-tablet: 600px;
$breakpoint-tablet-large: 900px;
$breakpoint-desktop: 1200px;
$breakpoint-desktop-med: 1600px;
$breakpoint-desktop-large: 1800px;
$breakpoint-desktop-xl: 2000px;
$breakpoint-desktop-huge: 2500px;

// Body
$page-margin: 1.25rem;
$page-margin-tablet: 2.5rem;
$page-margin-desktop: 4.6875rem;

// Colors
$white: #FFFFFF;
$dark-white: #F8F8F8;
$black: #000000;
$grey: #E4E4E4;
$dark-grey: #7D7D7D;
$darker-grey: #1B1B1B;
$red: #EE0000;
$rrm-red:#990000;
$dark-red: #9B0808;
$green: #0DB268;
$dark-green: #2B805A;
$blue: #ABDDE8;
$dark-blue: #385269;
$cream: #FAF5EA;
$tan: #EAE0C5;

// FONTS
$font: 'Helvetica Neue LT', Helvetica, Arial , sans-serif;
$font-charter: 'Charter ITC', Georgia, Garamond, serif;
$rrm-black: 'Helvetica Neue LT Standard Black', Helvetica, Arial, sans-serif;
$rrm-mdcd: 'Helvetica Neue LT Standard Medium Condensed', Helvetica, Arial, sans-serif;
$rrm-ltstcd: 'Helvetica Neue LT Standard Condensed', Helvetica, Arial, sans-serif;
