.rrm-home__text-area
{   
    padding: 4.5rem $page-margin;
    color: white;
    text-align: center;
    width: 100%;
    max-width: 49.063rem;
    margin: 0 auto;
    z-index: 0;
    
    .rrm-home-text-area__top
    {
        display: inline;
        text-align: center;
        text-transform: uppercase;
        padding: 5rem 1.25rem 0;

        h1
        {
            color: white;
            font-family: $rrm-black;
            line-height: 1.5rem;
            font-size: 3rem;
            letter-spacing: .063rem;
            margin-bottom: 0;
        }

        h1::after
        {
            content: ""; /* This is necessary for the pseudo element to work. */ 
            display: block; /* This will put the pseudo element on its own line. */
            margin: 0 auto; /* This will center the border. */
            width: 20.063rem; /* Change this to whatever width you want. */
            padding-top: 1.3rem; /* This creates some space between the element and the border. */
            border-bottom: .0125rem solid white; /* This creates the border. Replace black with whatever color you want. */

            #smallLine h1::after
            {
                width: 11.5rem;
            }

        }

        h2
        {
            font-family: $rrm-mdcd;
            font-weight: 100;
            letter-spacing: 0.0625rem;
            width: 100%;
            font-size: 2.125rem;
            margin-top: 1rem;
            margin-bottom: 0;
            word-wrap: none;
            word-break: keep-all;
        }

    }
    .rrm-home-text-area__bottom
    {    
        display: inline;
        p
        {
            color: white;
            font-family: $font;
            margin-top: 0;
            font-size: 18px;
        }

        #visitaddress
        {
            font-family: $rrm-ltstcd;
            line-height: 1.625rem;
            font-size: 1.125rem;
            color: #cc0000;
            font-weight: bolder;
            letter-spacing:1px;
        }
    }

    .rrm-home-text-area__button
    {
        padding-top: 1.5rem;
        margin-bottom: 1rem;
        
        button
        {
            font-size: 1.75rem;
            padding: 1.1rem;
            text-transform: uppercase;

            a
            {
                color: white;
            }
                :active, :hover
            {
                color: black;
            }
        }
    }

    .rrm-home-text-area__businesshours
    {
        div
        {
            display: inline-block;
            p
            {
            font-family: $rrm-black;
            font-size: 1.125rem;
            text-transform: uppercase;
            font-weight: bold;
            letter-spacing: .005rem;
            }
            #time
            {
                text-transform: none;
                font-weight: normal;
                font-family: $font;
            }
        }

        #weekdays p
        {
            margin-bottom: 2rem;
        }
    }
}

#redtext
{
    color: #990000;
    padding-top:6.6rem;

    h1
    {
        color: #990000;
    }

    h1::after
    {
        content: ""; /* This is necessary for the pseudo element to work. */ 
        display: block; /* This will put the pseudo element on its own line. */
        margin: 0 auto; /* This will center the border. */
        width: 15.8rem; /* Change this to whatever width you want. */
        padding-top: 1.3rem; /* This creates some space between the element and the border. */
        border-bottom: .0125rem solid#990000; /* This creates the border. Replace black with whatever color you want. */
    }

    p
    {
        color: black;
    }

}

#about
{
    h1::after
    {
        width: 11.4rem; /* Change this to whatever width you want. */
    }
}

#best
{
    h1::after
    {
        width: 15.5rem; /* Change this to whatever width you want. */
    }
}


