.rrm-home__header 
{
  overflow: hidden;

  &:hover {

    .slick-arrow {

      @media (min-width: $breakpoint-desktop) {
        opacity: .8;
      }
    }
  }
  
    .slick-arrow 
    {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: white;
      opacity: .8;
      color: transparent;
      @include size(3.6rem);
      border: none;
      z-index: 3;

      @media (min-width: $breakpoint-tablet) {
        @include size(4rem);
      }
      @media (min-width: $breakpoint-desktop) {
        opacity: 0;
      }

      &:focus,
      &:hover {
        outline: none;
      }

      &:active {
        background-color: rgba($darker-grey, 0.7);
      }

      &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 70%;
        transform: translate(-50%, -50%);
        @include size(3rem);
        z-index: 1;

        @media (min-width: $breakpoint-tablet) {
          @include size(4rem);
        }
      }

      &.slick-prev {

        @media ( max-width:920px)
        {
        left: 1.25rem;
        }


        @media (min-width: $breakpoint-desktop) {
          left: $page-margin;
        }

        &:after {
          content: '';
          background-image: url('../images/back.svg');
          left: 47%;
        }
      }
      &.slick-next {
        right: 0;
        left: auto;
        @media ( max-width:920px)
        {
        right: 1.25rem;
        }

        @media (min-width: $breakpoint-desktop) {
          right: $page-margin;
        }

        &:after {
          content: '';
          background-image: url('../images/next.svg');
          left: 53%;
        }
      }

      @media ( max-width:920px)
      {
        top: 84.5%;
      }
    }

  .rrm-home-header__slides
  {
  
    .home-header__slide 
    {
      position: relative;
      background-repeat: no-repeat;
      background-size: cover;
      padding: 6rem $page-margin 6rem;
      height: 40rem;
      background-position: center right 30%;


      @media (min-width: $breakpoint-tablet)
      {
        min-height: 47.52rem;
      }
      
      .home-header-slide__title
      {
        position: relative;
        background-color: $white;
        opacity: .8;
        width: 100%;
        max-width: 49.063rem;
        text-align: center;
        text-transform: uppercase;
        padding: 1.5rem 0;
      
        h1 
        {
          color: $black;
          font-family: $rrm-black;
          font-size: 2rem;
          letter-spacing: .033rem;
          font-weight: 700;
          line-height: 2.4rem;
          word-break: keep-all;
          z-index: 2;
          margin-bottom: .3rem;
          margin-top: 1.5rem;
    
          @media (min-width: $breakpoint-tablet)
          {
            font-size: 3rem;
          }
          @media (min-width: $breakpoint-desktop) 
          {
            font-size: 3.75rem;
            line-height: 4rem;
          }
        }
      }
      
      #title-bottom
      {
        h1
        {
          font-size: 2.63rem;
    
          @media (min-width: $breakpoint-tablet)
          {
            font-size: 3.63rem;
          }
          @media (min-width: $breakpoint-desktop)
          {
            font-size: 5rem;
          }
        }
      }
        
      .rrm-home-header-area__button
      {
        button
        {
            font-size: 1.75rem;
            padding: 1.1rem 1.5rem;
            text-transform: uppercase;
        }
      }
    }
  }

  button
  {
    a
    {
      color: white;
    }
    :active, :hover
    {
      color: black;
    }
  }
}