.rrm-home-flex-container__parent
{
    @media (min-width: $breakpoint-tablet)
    {
        display: flex;
    }

    #textsection
    {
        @media ( min-width: $breakpoint-tablet)
        {
            width: 50%;
        }
    }

    .gmap_canvas
    {
        margin-bottom: 0;
    }

    #gmap_canvas
    {
        margin-bottom: 0;
    }
}

.rrm-home-flex-container__parent-featureditems
{
    max-width: 95rem;
    margin: 0 auto;

    @media (min-width: $breakpoint-tablet)
    {
        display: flex;
    }

    .slick-dots 
    {
        list-style: none;
        display: block;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;

        li 
        {
            position: relative;
            display: inline-block;
            height: 20px;
            width: 20px;
            margin: 10px 0px 0px 0px;
            padding: 0;
            cursor: pointer;
        }
    }

    .slick-dots 
    {
        li
        {
            margin: 0 .5rem;

            button
            {
                background: white;
                border: 0;
                display: block;
                height: 15px;
                width: 15px;
                outline: none;
                border-radius: 50%;
                line-height: 0px;
                font-size: 0px;
                padding: 5px;

                &:hover,:focus,:active
                {
                    background: #990000;
                }

                &:before 
                {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    text-align: center;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                }
            }
            &.slick-active button:active
            {
                background: #990000;
            }

            &.slick-active button
            {
                background: #990000;
            }
        }
    }
}