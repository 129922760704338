/*------------------------------------*\
    $LIBRARIES
\*------------------------------------*/
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=f8da4e52-73c3-4612-b24e-ff93be67163e");
@font-face {
  font-family: "Helvetica Neue LT";
  src: url("/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix");
  src: url("/fonts/bf7ef4ce-de2a-4213-a9d7-826a3ee323cd.eot?#iefix") format("eot"), url("/fonts/5101995a-e73b-4cf9-84e8-f99eb43277b1.svg#5101995a-e73b-4cf9-84e8-f99eb43277b1") format("svg");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Helvetica Neue LT";
  src: url("/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix");
  src: url("/fonts/8a6c3e5a-88a5-430c-91d8-7767ce971d12.eot?#iefix") format("eot"), url("/fonts/531c5a28-5575-4f58-96d4-a80f7b702d7b.woff2") format("woff2"), url("/fonts/439c5962-f9fe-4eaf-a1f6-f41d42edba75.woff") format("woff"), url("/fonts/419a308d-b777-4f84-9235-2caf4a98ec23.ttf") format("truetype"), url("/fonts/e4d5b881-6835-45b7-8766-3e5b9e7cab8b.svg#e4d5b881-6835-45b7-8766-3e5b9e7cab8b") format("svg");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/bd4a6c95-033c-41f0-9510-2de2be19ebc6.eot?#iefix");
  src: url("/fonts/bd4a6c95-033c-41f0-9510-2de2be19ebc6.eot?#iefix") format("eot"), url("/fonts/6b354187-add6-421c-9022-918efeb1a9d4.woff2") format("woff2"), url("/fonts/fcf607e0-9726-46de-99a0-3d80440fcad0.woff") format("woff"), url("/fonts/3a077c2d-60d8-4c1e-947f-64cd5c57b382.ttf") format("truetype"), url("/fonts/da20fd94-cdae-454e-a5d9-e1790eeaf24b.svg#da20fd94-cdae-454e-a5d9-e1790eeaf24b") format("svg");
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/ba84e803-3903-4d83-bec4-16b23ae8ad1d.eot?#iefix");
  src: url("/fonts/ba84e803-3903-4d83-bec4-16b23ae8ad1d.eot?#iefix") format("eot"), url("/fonts/59ba0cae-532c-4764-970e-c8e9c4dc3621.woff2") format("woff2"), url("/fonts/c17e88a4-2bac-4c58-8aea-2345de107ef7.woff") format("woff"), url("/fonts/67daed65-8aec-4c77-9664-ca53f35c3734.ttf") format("truetype"), url("/fonts/c46a8071-15d2-4934-89bc-7e0db806414d.svg#c46a8071-15d2-4934-89bc-7e0db806414d") format("svg");
  font-style: italic;
  font-weight: 400; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/65803b4f-d9d0-4ddb-a9a5-9ab6ee15827e.eot?#iefix");
  src: url("/fonts/65803b4f-d9d0-4ddb-a9a5-9ab6ee15827e.eot?#iefix") format("eot"), url("/fonts/a26b8d77-7077-41a3-aa4a-9720f909f728.woff2") format("woff2"), url("/fonts/10deb463-297b-4976-8a13-f9a98d7ea6de.woff") format("woff"), url("/fonts/143c464c-244f-4c8e-a5f2-c28f78f092fc.ttf") format("truetype"), url("/fonts/2214883f-2c66-4e68-9fc8-3641bd9200ca.svg#2214883f-2c66-4e68-9fc8-3641bd9200ca") format("svg");
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/83fadcc0-5f33-4dfc-af40-cac829e5be93.eot?#iefix");
  src: url("/fonts/83fadcc0-5f33-4dfc-af40-cac829e5be93.eot?#iefix") format("eot"), url("/fonts/ddf06504-e618-494d-b1b9-56e26c0bf664.woff2") format("woff2"), url("/fonts/291df853-a088-4f20-9bb4-ce8097a4011b.woff") format("woff"), url("/fonts/3f4f26ae-3bd3-4b3c-91e0-daf64c948eda.ttf") format("truetype"), url("/fonts/279fffe9-9054-428f-be89-3c9cb4f57c2d.svg#279fffe9-9054-428f-be89-3c9cb4f57c2d") format("svg");
  font-style: italic;
  font-weight: 700; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/ffcecea9-4527-4be9-95f4-bea2860d6535.eot?#iefix");
  src: url("/fonts/ffcecea9-4527-4be9-95f4-bea2860d6535.eot?#iefix") format("eot"), url("/fonts/7c432744-fd05-46c3-8d26-d656c777bd5f.woff2") format("woff2"), url("/fonts/fabc29b0-3776-4b3d-922c-cdbe3ad31146.woff") format("woff"), url("/fonts/bf5cad83-7e03-48fa-bd2c-62b8871a6873.ttf") format("truetype"), url("/fonts/f43eb9f0-5f2e-4dce-b39e-1658aafc4873.svg#f43eb9f0-5f2e-4dce-b39e-1658aafc4873") format("svg");
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: "Charter ITC";
  src: url("/fonts/9e7fd5fd-ca11-4190-9d19-0be802a37c9d.eot?#iefix");
  src: url("/fonts/9e7fd5fd-ca11-4190-9d19-0be802a37c9d.eot?#iefix") format("eot"), url("/fonts/8f48b96f-3535-45f2-a3e7-c029b7b0d2d4.woff2") format("woff2"), url("/fonts/c4cd5d84-191e-4241-b89f-b68e2761baa8.woff") format("woff"), url("/fonts/4062d75d-b72c-4efa-9858-3115e9ae0e6f.ttf") format("truetype"), url("/fonts/91994cd0-4680-4eeb-a64e-9be12dfb84b4.svg#91994cd0-4680-4eeb-a64e-9be12dfb84b4") format("svg");
  font-style: italic;
  font-weight: 800; }

@font-face {
  font-family: "Helvetica Neue LT Standard Black";
  src: url("fonts/HelveticaNeueLTStd-Blk.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-Blk.woff") format("woff");
  font-style: normal; }

@font-face {
  font-family: "Helvetica Neue LT Standard Medium Condensed";
  src: url("fonts/HelveticaNeueLTStd-MdCn.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-MdCn.woff") format("woff");
  font-style: normal; }

@font-face {
  font-family: "Helvetica Neue LT Standard Condensed";
  src: url("fonts/HelveticaNeueLTStd-Cn.ttf") format("truetype"), url("/fonts/HelveticaNeueLTStd-Cn.woff") format("woff");
  font-style: normal;
  font-weight: 900; }

@font-face {
  font-family: 'Helvetica Neue LT Std 57 Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 57 Condensed"), url("/fonts/HelveticaNeueLTStd-Cn.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT Std 56 Italic';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 56 Italic"), url("/fonts/HelveticaNeueLTStd-It.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT Std 47 Light Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 47 Light Condensed"), url("/fonts/HelveticaNeueLTStd-LtCn.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT Std 45 Light';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 45 Light"), url("/fonts/HelveticaNeueLTStd Lt.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT Std 77 Bold Condensed';
  font-style: normal;
  font-weight: normal;
  src: local("Helvetica Neue LT Std 77 Bold Condensed"), url("/fonts/HelveticaNeueLTStd-BdCn.woff") format("woff"); }

@font-face {
  font-family: 'Helvetica Neue LT Std Condensed';
  font-style: bold;
  font-weight: bold;
  src: local("Helvetica Neue LT Std Condensed"), url("/fonts/HelveticaNeueLTStd-Cn.woff") format("woff");
  font-weight: 800; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/*------------------------------------*\
    HOME
\*------------------------------------*/
.rrm-home__header {
  overflow: hidden; }
  @media (min-width: 1200px) {
    .rrm-home__header:hover .slick-arrow {
      opacity: .8; } }
  .rrm-home__header .slick-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    opacity: .8;
    color: transparent;
    height: 3.6rem;
    width: 3.6rem;
    border: none;
    z-index: 3; }
    @media (min-width: 600px) {
      .rrm-home__header .slick-arrow {
        height: 4rem;
        width: 4rem; } }
    @media (min-width: 1200px) {
      .rrm-home__header .slick-arrow {
        opacity: 0; } }
    .rrm-home__header .slick-arrow:focus, .rrm-home__header .slick-arrow:hover {
      outline: none; }
    .rrm-home__header .slick-arrow:active {
      background-color: rgba(27, 27, 27, 0.7); }
    .rrm-home__header .slick-arrow:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
      transform: translate(-50%, -50%);
      height: 3rem;
      width: 3rem;
      z-index: 1; }
      @media (min-width: 600px) {
        .rrm-home__header .slick-arrow:after {
          height: 4rem;
          width: 4rem; } }
    @media (max-width: 920px) {
      .rrm-home__header .slick-arrow.slick-prev {
        left: 1.25rem; } }
    @media (min-width: 1200px) {
      .rrm-home__header .slick-arrow.slick-prev {
        left: 1.25rem; } }
    .rrm-home__header .slick-arrow.slick-prev:after {
      content: '';
      background-image: url("../images/back.svg");
      left: 47%; }
    .rrm-home__header .slick-arrow.slick-next {
      right: 0;
      left: auto; }
      @media (max-width: 920px) {
        .rrm-home__header .slick-arrow.slick-next {
          right: 1.25rem; } }
      @media (min-width: 1200px) {
        .rrm-home__header .slick-arrow.slick-next {
          right: 1.25rem; } }
      .rrm-home__header .slick-arrow.slick-next:after {
        content: '';
        background-image: url("../images/next.svg");
        left: 53%; }
    @media (max-width: 920px) {
      .rrm-home__header .slick-arrow {
        top: 84.5%; } }
  .rrm-home__header .rrm-home-header__slides .home-header__slide {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 6rem 1.25rem 6rem;
    height: 40rem;
    background-position: center right 30%; }
    @media (min-width: 600px) {
      .rrm-home__header .rrm-home-header__slides .home-header__slide {
        min-height: 47.52rem; } }
    .rrm-home__header .rrm-home-header__slides .home-header__slide .home-header-slide__title {
      position: relative;
      background-color: #FFFFFF;
      opacity: .8;
      width: 100%;
      max-width: 49.063rem;
      text-align: center;
      text-transform: uppercase;
      padding: 1.5rem 0; }
      .rrm-home__header .rrm-home-header__slides .home-header__slide .home-header-slide__title h1 {
        color: #000000;
        font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
        font-size: 2rem;
        letter-spacing: .033rem;
        font-weight: 700;
        line-height: 2.4rem;
        word-break: keep-all;
        z-index: 2;
        margin-bottom: .3rem;
        margin-top: 1.5rem; }
        @media (min-width: 600px) {
          .rrm-home__header .rrm-home-header__slides .home-header__slide .home-header-slide__title h1 {
            font-size: 3rem; } }
        @media (min-width: 1200px) {
          .rrm-home__header .rrm-home-header__slides .home-header__slide .home-header-slide__title h1 {
            font-size: 3.75rem;
            line-height: 4rem; } }
    .rrm-home__header .rrm-home-header__slides .home-header__slide #title-bottom h1 {
      font-size: 2.63rem; }
      @media (min-width: 600px) {
        .rrm-home__header .rrm-home-header__slides .home-header__slide #title-bottom h1 {
          font-size: 3.63rem; } }
      @media (min-width: 1200px) {
        .rrm-home__header .rrm-home-header__slides .home-header__slide #title-bottom h1 {
          font-size: 5rem; } }
    .rrm-home__header .rrm-home-header__slides .home-header__slide .rrm-home-header-area__button button {
      font-size: 1.75rem;
      padding: 1.1rem 1.5rem;
      text-transform: uppercase; }
  .rrm-home__header button a {
    color: white; }
  .rrm-home__header button :active, .rrm-home__header button :hover {
    color: black; }

.rrm-home__featured-items {
  background-color: #000000;
  padding: 4.5rem;
  color: white;
  text-align: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden; }
  @media (min-width: 600px) {
    .rrm-home__featured-items {
      padding: 4.5rem 10rem; } }
  .rrm-home__featured-items .rrm-home-text-area__top {
    display: inline;
    text-align: center;
    text-transform: uppercase;
    padding: 5rem 1.25rem 0;
    max-width: 49.063rem; }
    .rrm-home__featured-items .rrm-home-text-area__top h1 {
      color: white;
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      line-height: 1.5rem;
      font-size: 3rem;
      letter-spacing: .063rem;
      margin-bottom: 0; }
    .rrm-home__featured-items .rrm-home-text-area__top h1::after {
      content: "";
      /* This is necessary for the pseudo element to work. */
      display: block;
      /* This will put the pseudo element on its own line. */
      margin: 0 auto;
      /* This will center the border. */
      width: 20.063rem;
      /* Change this to whatever width you want. */
      padding-top: 1.75rem;
      /* This creates some space between the element and the border. */
      border-bottom: .0125rem solid white;
      /* This creates the border. Replace black with whatever color you want. */ }
    .rrm-home__featured-items .rrm-home-text-area__top h2 {
      font-family: "Helvetica Neue LT Standard Medium Condensed", Helvetica, Arial, sans-serif;
      font-weight: 100;
      letter-spacing: 0.0625rem;
      width: 100%;
      font-size: 2.125rem;
      margin-top: 1rem;
      margin-bottom: 0;
      word-wrap: none;
      word-break: keep-all; }
  .rrm-home__featured-items .rrm-home-featured-items__panel {
    width: 100%;
    text-align: center;
    margin: 0 auto; }
    @media (min-width: 600px) {
      .rrm-home__featured-items .rrm-home-featured-items__panel {
        flex-wrap: wrap;
        max-width: 12.6rem; } }
    @media (min-width: 900px) {
      .rrm-home__featured-items .rrm-home-featured-items__panel {
        max-width: 13.5rem; } }
    .rrm-home__featured-items .rrm-home-featured-items__panel .rrm-home-featured-items-panel__image {
      height: 11.56rem;
      width: 11.56rem;
      margin: auto; }
    .rrm-home__featured-items .rrm-home-featured-items__panel .rrm-home-featured-items-panel__title {
      text-transform: uppercase;
      font-size: 1.25rem;
      letter-spacing: .069rem;
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      color: white;
      margin-right: 0; }
      .rrm-home__featured-items .rrm-home-featured-items__panel .rrm-home-featured-items-panel__title h3 {
        font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
        font-size: 1.25rem;
        width: 10.5rem;
        margin-top: 0.2rem; }
    .rrm-home__featured-items .rrm-home-featured-items__panel .rrm-home-featured-items-panel__text {
      display: inline-block; }
      .rrm-home__featured-items .rrm-home-featured-items__panel .rrm-home-featured-items-panel__text p {
        color: white;
        font-family: "Helvetica Neue LT", Helvetica, Arial, sans-serif;
        margin-top: 0;
        width: 12.56rem;
        font-weight: 100;
        font-size: 16px; }
  .rrm-home__featured-items .rrm-home-featured-item-panels {
    display: inline-block;
    padding: 4.5rem 1.25rem;
    color: white;
    text-align: center;
    width: 100%;
    max-width: 97.81rem; }
    .rrm-home__featured-items .rrm-home-featured-item-panels .rrm-home-text-area__bottom {
      display: inline; }
      .rrm-home__featured-items .rrm-home-featured-item-panels .rrm-home-text-area__bottom p {
        color: white;
        font-family: "Helvetica Neue LT", Helvetica, Arial, sans-serif;
        margin-top: 0;
        font-weight: 100;
        font-size: 18px; }
      .rrm-home__featured-items .rrm-home-featured-item-panels .rrm-home-text-area__bottom #visitaddress {
        font-family: "Helvetica Neue LT Standard Condensed", Helvetica, Arial, sans-serif;
        line-height: 1.625rem;
        font-size: 1.125rem;
        color: #990000;
        font-weight: bolder; }
    .rrm-home__featured-items .rrm-home-featured-item-panels .rrm-home-text-area__button {
      padding-top: 1.5rem;
      margin-bottom: 1rem; }
      .rrm-home__featured-items .rrm-home-featured-item-panels .rrm-home-text-area__button button {
        font-size: 1.75rem;
        padding: 1.1rem;
        text-transform: uppercase; }
  .rrm-home__featured-items #featured-items {
    flex-wrap: wrap;
    justify-content: space-evenly; }

.rrm-home__image {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 25rem;
  width: 100%; }
  @media (min-width: 600px) {
    .rrm-home__image {
      width: 50%; } }

.rrm-home__text-area {
  padding: 4.5rem 1.25rem;
  color: white;
  text-align: center;
  width: 100%;
  max-width: 49.063rem;
  margin: 0 auto;
  z-index: 0; }
  .rrm-home__text-area .rrm-home-text-area__top {
    display: inline;
    text-align: center;
    text-transform: uppercase;
    padding: 5rem 1.25rem 0; }
    .rrm-home__text-area .rrm-home-text-area__top h1 {
      color: white;
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      line-height: 1.5rem;
      font-size: 3rem;
      letter-spacing: .063rem;
      margin-bottom: 0; }
    .rrm-home__text-area .rrm-home-text-area__top h1::after {
      content: "";
      /* This is necessary for the pseudo element to work. */
      display: block;
      /* This will put the pseudo element on its own line. */
      margin: 0 auto;
      /* This will center the border. */
      width: 20.063rem;
      /* Change this to whatever width you want. */
      padding-top: 1.3rem;
      /* This creates some space between the element and the border. */
      border-bottom: .0125rem solid white;
      /* This creates the border. Replace black with whatever color you want. */ }
      .rrm-home__text-area .rrm-home-text-area__top h1::after #smallLine h1::after {
        width: 11.5rem; }
    .rrm-home__text-area .rrm-home-text-area__top h2 {
      font-family: "Helvetica Neue LT Standard Medium Condensed", Helvetica, Arial, sans-serif;
      font-weight: 100;
      letter-spacing: 0.0625rem;
      width: 100%;
      font-size: 2.125rem;
      margin-top: 1rem;
      margin-bottom: 0;
      word-wrap: none;
      word-break: keep-all; }
  .rrm-home__text-area .rrm-home-text-area__bottom {
    display: inline; }
    .rrm-home__text-area .rrm-home-text-area__bottom p {
      color: white;
      font-family: "Helvetica Neue LT", Helvetica, Arial, sans-serif;
      margin-top: 0;
      font-size: 18px; }
    .rrm-home__text-area .rrm-home-text-area__bottom #visitaddress {
      font-family: "Helvetica Neue LT Standard Condensed", Helvetica, Arial, sans-serif;
      line-height: 1.625rem;
      font-size: 1.125rem;
      color: #cc0000;
      font-weight: bolder;
      letter-spacing: 1px; }
  .rrm-home__text-area .rrm-home-text-area__button {
    padding-top: 1.5rem;
    margin-bottom: 1rem; }
    .rrm-home__text-area .rrm-home-text-area__button button {
      font-size: 1.75rem;
      padding: 1.1rem;
      text-transform: uppercase; }
      .rrm-home__text-area .rrm-home-text-area__button button a {
        color: white; }
      .rrm-home__text-area .rrm-home-text-area__button button :active, .rrm-home__text-area .rrm-home-text-area__button button :hover {
        color: black; }
  .rrm-home__text-area .rrm-home-text-area__businesshours div {
    display: inline-block; }
    .rrm-home__text-area .rrm-home-text-area__businesshours div p {
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      font-size: 1.125rem;
      text-transform: uppercase;
      font-weight: bold;
      letter-spacing: .005rem; }
    .rrm-home__text-area .rrm-home-text-area__businesshours div #time {
      text-transform: none;
      font-weight: normal;
      font-family: "Helvetica Neue LT", Helvetica, Arial, sans-serif; }
  .rrm-home__text-area .rrm-home-text-area__businesshours #weekdays p {
    margin-bottom: 2rem; }

#redtext {
  color: #990000;
  padding-top: 6.6rem; }
  #redtext h1 {
    color: #990000; }
  #redtext h1::after {
    content: "";
    /* This is necessary for the pseudo element to work. */
    display: block;
    /* This will put the pseudo element on its own line. */
    margin: 0 auto;
    /* This will center the border. */
    width: 15.8rem;
    /* Change this to whatever width you want. */
    padding-top: 1.3rem;
    /* This creates some space between the element and the border. */
    border-bottom: 0.0125rem solid #990000;
    /* This creates the border. Replace black with whatever color you want. */ }
  #redtext p {
    color: black; }

#about h1::after {
  width: 11.4rem;
  /* Change this to whatever width you want. */ }

#best h1::after {
  width: 15.5rem;
  /* Change this to whatever width you want. */ }

@media (min-width: 600px) {
  .rrm-home-flex-container__parent {
    display: flex; } }

@media (min-width: 600px) {
  .rrm-home-flex-container__parent #textsection {
    width: 50%; } }

.rrm-home-flex-container__parent .gmap_canvas {
  margin-bottom: 0; }

.rrm-home-flex-container__parent #gmap_canvas {
  margin-bottom: 0; }

.rrm-home-flex-container__parent-featureditems {
  max-width: 95rem;
  margin: 0 auto; }
  @media (min-width: 600px) {
    .rrm-home-flex-container__parent-featureditems {
      display: flex; } }
  .rrm-home-flex-container__parent-featureditems .slick-dots {
    list-style: none;
    display: block;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%; }
    .rrm-home-flex-container__parent-featureditems .slick-dots li {
      position: relative;
      display: inline-block;
      height: 20px;
      width: 20px;
      margin: 10px 0px 0px 0px;
      padding: 0;
      cursor: pointer; }
  .rrm-home-flex-container__parent-featureditems .slick-dots li {
    margin: 0 .5rem; }
    .rrm-home-flex-container__parent-featureditems .slick-dots li button {
      background: white;
      border: 0;
      display: block;
      height: 15px;
      width: 15px;
      outline: none;
      border-radius: 50%;
      line-height: 0px;
      font-size: 0px;
      padding: 5px; }
      .rrm-home-flex-container__parent-featureditems .slick-dots li button:hover, .rrm-home-flex-container__parent-featureditems .slick-dots li button :focus, .rrm-home-flex-container__parent-featureditems .slick-dots li button :active {
        background: #990000; }
      .rrm-home-flex-container__parent-featureditems .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .rrm-home-flex-container__parent-featureditems .slick-dots li.slick-active button:active {
      background: #990000; }
    .rrm-home-flex-container__parent-featureditems .slick-dots li.slick-active button {
      background: #990000; }

.rrm-home__footer {
  background-color: #666666;
  width: 100%;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  padding: 3rem 1rem; }
  @media (min-width: 600px) {
    .rrm-home__footer {
      justify-content: space-evenly; } }
  .rrm-home__footer h1 {
    text-transform: uppercase;
    font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
    color: white;
    font-size: 1.25rem;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-top: unset; }
  .rrm-home__footer p {
    font-family: "Helvetica Neue LT", Helvetica, Arial, sans-serif;
    color: white;
    font-size: 1rem;
    font-weight: 400; }
  .rrm-home__footer img {
    color: white; }
  .rrm-home__footer button {
    font-size: 1.75rem;
    padding: .5rem 2rem;
    font-family: "Helvetica Neue LT Standard Medium Condensed", Helvetica, Arial, sans-serif;
    text-transform: uppercase;
    padding-top: 1rem; }
    .rrm-home__footer button a {
      color: white; }
    .rrm-home__footer button :active, .rrm-home__footer button :hover {
      color: black; }
  .rrm-home__footer div {
    display: inline-block; }
    .rrm-home__footer div p {
      max-width: 17.8125rem;
      margin: 0; }
  .rrm-home__footer .rrm-home-footer__first-section {
    width: 17.8125rem;
    margin-bottom: 2rem; }
    .rrm-home__footer .rrm-home-footer__first-section p {
      font-weight: 100; }
  .rrm-home__footer .rrm-home-footer__second-section {
    width: 15rem;
    margin-bottom: 2rem;
    text-decoration: none; }
    .rrm-home__footer .rrm-home-footer__second-section p {
      font-weight: 100;
      text-decoration: none; }
      .rrm-home__footer .rrm-home-footer__second-section p a {
        color: white;
        text-decoration: none; }
      .rrm-home__footer .rrm-home-footer__second-section p :link, .rrm-home__footer .rrm-home-footer__second-section p :active, .rrm-home__footer .rrm-home-footer__second-section p :visited {
        text-decoration: none; }
    .rrm-home__footer .rrm-home-footer__second-section h6 {
      color: white;
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      margin: 0; }
    .rrm-home__footer .rrm-home-footer__second-section div img {
      margin: 1.25rem 1.25rem 1.25rem 0; }
  .rrm-home__footer .rrm-home-footer__third-section {
    width: 15rem;
    letter-spacing: 1px;
    font-size: 1.25rem;
    letter-spacing: 1px;
    margin-bottom: 0;
    margin-bottom: 2rem; }
    .rrm-home__footer .rrm-home-footer__third-section p {
      text-transform: uppercase;
      font-family: "Helvetica Neue LT Standard Black", Helvetica, Arial, sans-serif;
      color: white;
      font-size: 1.25rem;
      letter-spacing: 1px;
      margin-bottom: 0;
      margin-top: unset; }

#fbslide {
  padding: 3.5rem 1.25rem 6rem; }

.l-contact {
  margin-top: 0; }
