.rrm-home__footer
{
    background-color: #666666;width: 100%;
    width:100%;
    margin: 0 auto;
    //margin-top: -.5rem;
    //z-index: 10;
    
    display: flex;
    flex-wrap: wrap;

    padding: 3rem 1rem;

    @media (min-width:$breakpoint-tablet)
    {
        justify-content: space-evenly;
    }

    @media (min-width:$breakpoint-desktop)
    {

    }

    h1
    {
        text-transform: uppercase;
        font-family: $rrm-black;
        color: white;
        font-size: 1.25rem;
        letter-spacing: 1px;
        margin-bottom: 0;
        margin-top: unset;
    }

    p
    {
        font-family: $font;
        color: white;
        font-size: 1rem;
        font-weight: 400;
    }

    img
    {
        color: white;
    }

    button
    {
        font-size: 1.75rem;
        padding: .5rem 2rem;
        font-family: $rrm-mdcd;
        text-transform: uppercase;
        padding-top: 1rem;

        a
        {
            color: white;
        }

        :active, :hover
        {
            color: black;
        }
    }

    div
    {
        display: inline-block;
        p
        {
            max-width: 17.8125rem;
            margin: 0;
        }
    }

    .rrm-home-footer__first-section
    {
       width: 17.8125rem;
       margin-bottom: 2rem;

       p
       {
           font-weight: 100;
       }
    }

    .rrm-home-footer__second-section
    {
        width: 15rem;
        margin-bottom: 2rem;
        text-decoration: none;

        p
        {
            font-weight: 100;
            text-decoration: none;

            a
            {
                color: white;
                text-decoration:none;
            }
            :link, :active, :visited
            {
                text-decoration: none;
            }
        }

        h6
        {
            color: white;
            font-family: $rrm-black;
            margin:0;
        }

        div
        {
            img
            {
                margin:1.25rem 1.25rem 1.25rem 0;
            }
        }

        
    }

    .rrm-home-footer__third-section
    {
        width: 15rem;
        letter-spacing: 1px;
        font-size: 1.25rem;
        letter-spacing: 1px;
        margin-bottom: 0;
        margin-bottom: 2rem;

        p
        {
            text-transform: uppercase;
            font-family: $rrm-black;
            color: white;
            font-size: 1.25rem;
            letter-spacing: 1px;
            margin-bottom: 0;
            margin-top: unset;
        }
    }
}